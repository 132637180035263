import "./App.css";

import { Container, Navbar, Row } from "react-bootstrap";

// const Header = () => (
// 	<Navbar bg="dark" variant="dark" fixed="top">
// 		<Container>
// 			<Navbar.Brand href="#home">UNRAVLR </Navbar.Brand>{" "}
// 		</Container>
// 	</Navbar>
// );

const Footer = () => (
	<Navbar fixed="bottom" bg="dark" variant="dark">
		<Container>
			<Navbar.Text href="#home">UNRAVLR 2020</Navbar.Text>
		</Container>
	</Navbar>
);

function App() {
	return (
		<Container fluid>
			<Row></Row>
			<Row>
				<Container fluid className="mainBody">
					<div className="jumbotron d-flex align-items-center min-vh-100">
						<div className="container text-center">
							<h2>Hello.</h2>
						</div>
					</div>
				</Container>
			</Row>
			<Row>
				<Footer className="appFooter" />
			</Row>
		</Container>
	);
}

export default App;
